import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AudioStyle,
  DashboardContainer,
  DashboardContainerDesKtop,
} from './styled';
import GameFooter from '../../components/Footer';
import GameHeaderV2 from '../../components/Header/indexv2';
import { useSelector } from 'react-redux';
import LoadingV2 from '../../page/LoadingV2';
import { useEffect, useState } from 'react';
import { isMobile } from '../../utils/checkingMobile';

const LayoutDashboard = () => {
  const { infoUser } = useSelector((state: any) => state.point);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSecond] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  // const countDownTime = () => {
  //   // Set the date we're counting down to
  //   // if (!data) return;

  //   const currentHour = 7;
  //   const timeUTC = ((new Date().getTimezoneOffset() * 60) / (60 * 60)) * -1;
  //   const countDownDate = new Date(
  //     `09/30/2024 ${currentHour + timeUTC}:30`
  //   ).getTime();

  //   // Update the count down every 1 second
  //   const x = setInterval(function () {
  //     // Get today's date and time
  //     const now = new Date().getTime();

  //     // Find the distance between now and the count down date
  //     const distance = countDownDate - now;

  //     // Time calculations for days, hours, minutes and seconds
  //     // Time calculations for days, hours, minutes and seconds
  //     setHours(
  //       Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
  //         ? 0
  //         : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  //     );
  //     setMinutes(
  //       Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
  //         ? 0
  //         : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  //     );
  //     setSecond(
  //       Math.floor((distance % (1000 * 60)) / 1000) <= 0
  //         ? 0
  //         : Math.floor((distance % (1000 * 60)) / 1000)
  //     );

  //     if (distance <= 0 && (infoUser && location.pathname === '/')) {
  //       clearInterval(x);
  //       navigate('/clicker');
  //     } else {
  //       navigate('/count-down')
  //     }
  //   }, 1000);
  // };
  // useEffect(() => {
  //   countDownTime();
  // }, [infoUser]);
  
  useEffect(() => {
    if (infoUser && location.pathname === '/') {
      navigate('/assets');
    }
  }, [infoUser]);

  useEffect(() => {
    const overflow = 100;
    document.body.style.overflowY = 'hidden';
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = window.innerHeight + overflow + 'px';
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);
  }, []);

  return (
    <DashboardContainer>
      {isMobile() ? (
        <>
          {infoUser ? (
            <>
              <GameHeaderV2 />
              {/* <AudioStyle onClickCapture={toggleAudio}>
                <div className="img-audio">
                  {isPlaying ? (
                    <img src="/static/icon-play.png" alt="play" />
                  ) : (
                    <img src="/static/icon-pause.png" alt="pause" />
                  )}
                </div>
              </AudioStyle> */}
              <Outlet />
              <GameFooter />
            </>
          ) : (
            <LoadingV2 />
          )}
        </>
      ) : (
        <DashboardContainerDesKtop>
          <h1>Play on your phone, please!</h1>
          <div className="img-qr-game">
            <img src="/static/qr-game.jpg" alt="game" />
          </div>
        </DashboardContainerDesKtop>
      )}
    </DashboardContainer>
  );
};
export default LayoutDashboard;
