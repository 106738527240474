import styled from "styled-components";
import bg from "../../assets/Game/Assets/bg_01.png";
import bgShop from "../../assets/Game/Assets/bg_shop.png";
import iconShop from "../../assets/Game/Assets/icn_02.png";
import bgPoint from "../../assets/Game/Assets/total_point_kibble.png";

export const AssetsContainer = styled.div`
  padding: 80px 15px 0;
  position: relative;
  &.is-shop {
    padding: 0;
  }
  &.is-overlay {
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
      pointer-events: none;
      opacity: 1;
      transition: all 0.26s ease;
    }
  }
`;
export const AssetsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AssetsContentInner = styled.div`
  padding: 0 12px;
  border-radius: 12px;
  background: #1b2036;
  box-shadow: 0px -7px 4px 0px #03050d inset, 0px 6px 0px 0px #4b5dad inset;
`;
export const AssetsContent = styled.div`
  color: var(--Neutral-50, #f7f7f8);
  text-align: center;
  font-family: ErasBoldITC;
  font-size: 34.621px;
  font-style: normal;
  font-weight: 400;
  min-height: 553px;
  width: 100%;
  &.is-shop {
    /* padding-top: 70px; */
    ${AssetsContentInner} {
      box-shadow: unset;
      background: unset;
      padding: unset;
      height: 85vh;
      height: 85svh;
      overflow: auto;
    }
  }
`;

export const AssetsContentTop = styled.div`
  /* padding: 10px 0; */
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 10px;
  .tab-active {
    border: 1.509px solid #fff;
    background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
    box-shadow: 0px -5px 0px 0px #fd3801 inset;
  }
`;
export const ConnectWallet = styled.button`
  flex-shrink: 0;
  display: block;
  width: 100%;
  min-height: 54px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 96%; /* 15.36px */
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: #fdc920;
  box-shadow: 0px -5px 0px 0px #ce6000 inset;
  span {
    color: #ff6200;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 15.36px */
    text-transform: uppercase;
  }
`;
export const ButtonShop = styled.div`
  max-width: 50%;
  width: 100%;
  border-radius: 9px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  background-image: url("/assets/images/bg-btn-airdrop.png");
  background-position: center;
  background-size: cover;
  gap: 5px;
  cursor: pointer;
  &:nth-child(2) {
    background-image: url("/assets/images/bg-btn-shop.png");
  }
  p {
    color: #fff;
    text-align: center;
    font-family: ErasBoldITC;
    font-size: 16px;
    line-height: 1;
  }
  .img-btn {
    max-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;

export const FormSubmit = styled.div`
  margin: 5px 0;
  h1 {
    color: #fff;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: "Berlin Sans FB Demi";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.56px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 3px;
  }
  input {
    outline: none;
    border-radius: 8px;
    border: 2px solid #ffc72e;
    background: #fff;
    box-shadow: 0px -6px 0px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    padding: 15px 26px 19px 12px;
    align-items: center;
    align-self: stretch;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    letter-spacing: -0.8px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const AddressUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center;
  width: 100%;
  padding: 18px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid #ffc72e;
  background: #fff;
  box-shadow: 0px -6px 0px 0px rgba(0, 0, 0, 0.25) inset;
  border-bottom: 0;
  p {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    font-family: "Berlin Sans FB Demi";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    letter-spacing: -0.8px;
  }
  .img-copy {
    max-width: 24px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
`;

export const WrapCountDownTime = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  .img-title {
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
`;

export const CountDownTimeBlock = styled.div`
  background-image: url("/assets/images/bg-count.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 218.66px;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
  margin-top: -30px;
  z-index: -1;
  padding: 0 10px;
`;

export const TimesRamdom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  gap: 3px;
  .img-coin {
    max-width: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56.516px;
    transform: rotate(5.483deg);
    margin-left: 5px;
    img {
      width: 100%;
    }
  }
`;

export const BlockTimes = styled.div`
  background-image: url("/assets/images/bg-number.png");
  background-position: center;
  background-size: 100%;
  width: max-content;
  min-width: 120px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
`;

export const WrapTimes = styled.div`
  display: flex;
  width: 100%;
  height: 53px;
  justify-content: center;
  align-items: center;
  gap: -2.738px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #844d00;
  box-shadow: 0px 5px 7.7px 0px rgba(0, 0, 0, 0.25) inset;
  gap: 5px;
  margin-top: 20px;
  padding-top: 5px;
  h1 {
    color: #fff;
    text-align: center;
    font-family: "Coda Caption";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 14.822px */
  }
`;

export const AssetsContentBottom = styled.div``;
export const AssetsContentWallet = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 27px 0 15px;
  h3 {
    color: #fff;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 15.36px */
    letter-spacing: -0.64px;
    text-transform: uppercase;
  }
`;
export const Total = styled.div`
  border-top: 2px solid #3b488a;
  background: #37406d;
  padding: 7px 13px 13px 11px;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px -7px 4px 0px #03050d inset;
  margin: 20px -12px 0;
`;
export const TotalInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url("/Assets/bg_product.png") center / 100% auto no-repeat;
  padding: 11px 20px;
  position: relative;
  z-index: 1;
  .total-balance {
    color: #fff;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
  }
  .price {
    color: #fff;
    text-align: right;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 15.36px */
    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      width: 57%;
      z-index: -1;
      background: url("/Assets/bg_product_02.png") center / 100% auto no-repeat;
    }
  }
`;
export const ListToken = styled.ul`
  max-height: 342px;
  overflow: auto;
  height: calc(100vh - 460px);
  height: calc(100svh - 460px);
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("/Assets/bg_product.png") center / 100% auto no-repeat;
    padding: 0 20px;
    position: relative;
    height: 42px;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    .content-coin {
      display: flex;
      align-items: center;
    }
    .img-coin {
      margin-right: 5px;
      line-height: 1;
      position: relative;
      top: 2px;
    }
    img {
      width: 26px;
      border-radius: 50%;
    }
    h3 {
      overflow: hidden;
      color: var(--Neutral-50, #f7f7f8);
      text-overflow: ellipsis;
      font-family: "Berlin Sans FB Demi";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 18.75px */
    }
  }
  .price {
    font-family: "Berlin Sans FB Demi";
    width: 55%;
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 15.36px */
    z-index: 1;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      width: 55%;
      z-index: -1;
      background: url("/Assets/bg_product_02.png") center / 100% auto no-repeat;
    }
  }
`;

//content shop
export const BgTop = styled.figure`
  position: absolute;
  top: 79px;
  left: 0;
  width: 100%;
  overflow: hidden;
  ${AssetsContentTop} {
    overflow: hidden;
  }
`;
export const ButtonBack = styled.div`
  width: 117px;
  img {
    width: 100%;
  }
`;
export const ContentPoint = styled.div`
  background: url(${bgPoint}) center / 156px auto no-repeat;
  width: 156px;
  height: 40px;
  text-align: center;
  span {
    width: 78%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
    text-align: center;
    font-family: Franklin;
    font-size: 15px;
    font-style: normal;
    line-height: 1;
    text-transform: lowercase;
  }
`;

export const ListProduct = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  li {
    list-style-type: none;
    width: calc(100% / 2 - 10px);
    img {
      width: 100%;
    }
    &.is-full {
      width: 100%;
    }
  }
`;
