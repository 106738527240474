import {
  AddressUser,
  AssetsContainer,
  AssetsContent,
  AssetsContentInner,
  AssetsContentTop,
  AssetsWrapper,
  BlockTimes,
  ButtonShop,
  ConnectWallet,
  CountDownTimeBlock,
  FormSubmit,
  TimesRamdom,
  WrapCountDownTime,
  WrapTimes,
} from "./styled";
import icon01 from "../../assets/Game/Assets/icn_01.svg";
import product01 from "../../assets/Game/Assets/product_img_01.png";
import product02 from "../../assets/Game/Assets/product_img_02.png";
import product03 from "../../assets/Game/Assets/product_img_03.png";
import icnUser from "../../assets/Game/Assets/icn_user.png";
import { useEffect, useState } from "react";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import WalletModalInfo from "../../components/Common/WalletInfo";
import AssetsShopV2 from "../Assets/AssetsShopV2";
import { convertFixed } from "../../utils/convertFormatNumber";
import WalletInfo from "./WalletInfo";
import WebApp from "@twa-dev/sdk";
import { postEvent, useInitData } from "@tma.js/sdk-react";
import { AssetsWrap, AssetsWrapTab } from "../Assets/AssetsShopV2/styled";
import { useSelector } from "react-redux";
import web3 from "web3";
import { useDispatch } from "../../redux/store";
import {
  getDataInfoUser,
  getDataListAsset,
} from "../../redux/features/pointSlice";
import { toast } from "react-hot-toast";
import { KIBBLE_API } from "../../services/api";
import { shortenAddress } from "../../utils/addressUser";
import { formatUnitTime } from "../../utils/formatUnitTime";

const AssetsNew = () => {
  const [showShop, setShowShop] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const [overlayPremium, setOverlayPremium] = useState(false);
  const [tonTokenInWallet, setTonTokenInWallet] = useState<any>(null);
  const [valueAddress, setValueAddress] = useState("");
  const address = useTonAddress();
  const { listAsset, infoUser } = useSelector((state: any) => state.point);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSecond] = useState(0);
  const [isClaim, setIsClaim] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenAirdrop, setTokenAirdrop] = useState<any>(null);

  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;
  const dispatch = useDispatch();

  const countDownTime = (time:any) => {
    // Set the date we're counting down to
    if (!time) return;

    const countDownDate = new Date(
      time
    ).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      setDays(
        Math.floor(distance / (1000 * 60 * 60 * 24)) <= 0
          ? 0
          : Math.floor(distance / (1000 * 60 * 60 * 24))
      );
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      setSecond(
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000)
      );

      if (distance <= 0) {
        clearInterval(x);
        setIsClaim(true);
      }
    }, 1000);
  };
  useEffect(() => {
    if(tokenAirdrop) {
      countDownTime(tokenAirdrop?.available_at);
    }
  }, [tokenAirdrop]);

  const handleShowShop = () => {
    setShowShop(true);
    WebApp.BackButton.show();
  };

  const hiddenShop = () => {
    setShowShop(false);
  };
  //connect wallet
  const onpenWalletInfo = async () => {
    setOpenConfirmModal(true);
  };
  const handleCancel = async () => {
    setOpenConfirmModal(false);
  };

  const handleSubmitWallet = async () => {
    try {
      const isAddress = web3.utils.isAddress(valueAddress);
      if (isAddress) {
        await KIBBLE_API.submitAddress({
          address: valueAddress,
        });
        dispatch(getDataInfoUser(user));
        toast.success("Submit Address Successfully");
      } else {
        toast.error("Invalid Address");
      }
    } catch (error) {
      console.log("====================================");
      console.log("handleSubmitWallet err", error);
      console.log("====================================");
      toast.error("Submit Address Failed");
    }
  };

  const onChangAddress = (e: any) => {
    const { value } = e.target;
    setValueAddress(value);
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(infoUser?.address);
    toast.success("Copied");
  };

  const handleClaim = async () => {
   try {
    if (!isClaim || isLoading || tokenAirdrop?.has_claimed) return;
    setIsLoading(true)
    await KIBBLE_API.claimTokens()
    await handleGetTokenAirdrops()
    toast.success('Claim Successfully')
   } catch (error:any) {
    setIsLoading(false)
    toast.error(
      error && error.message
        ? error.message.substring(0, 55) + "..."
        : "Claim Failed"
    );
    console.log('====================================');
    console.log('handleClaim err', error);
    console.log('====================================');
   }
  };

  const handleGetTokenAirdrops = async () => {
    try {
      const { data } = await KIBBLE_API.getTokenAirdrop()
      setTokenAirdrop(data)
    } catch (error) {
      console.log('====================================');
      console.log('handleGetTokenAirdrops err', error);
      console.log('====================================');
    }
  }

  // wallet info
  useEffect(() => {
    postEvent("web_app_setup_back_button", {
      is_visible: showShop,
    });
    WebApp.onEvent("backButtonClicked", hiddenShop);
    return () => {
      WebApp.offEvent("backButtonClicked", hiddenShop);
    };
  }, [showShop]);

  useEffect(() => {
    if (listAsset && listAsset.length > 0) {
      const tonToken = listAsset.find(
        (item: any) =>
          item.contract_address === process.env.REACT_APP_TON_ADDRESS
      );
      setTonTokenInWallet(tonToken);
    } else {
      dispatch(getDataListAsset(address));
    }
  }, [listAsset, address]);

  useEffect(() => {
    if(infoUser && infoUser.address) {
      handleGetTokenAirdrops()
    }
  }, [infoUser])

  return (
    <AssetsContainer
      className={`${
        showShop
          ? "is-shop animate__animated animate__fadeIn"
          : "animate__animated animate__fadeIn"
      } ${
        overlayPremium
          ? "is-overlay animate__animated animate__fadeIn"
          : "animate__animated animate__fadeIn"
      }`}
    >
      <AssetsWrapper>
        <AssetsContent className={showShop ? "is-shop" : ""}>
          <AssetsContentTop>
            {!showShop ? (
              <AssetsWrap>
                <AssetsWrapTab>
                  <ButtonShop>
                    <div className="img-btn">
                      <img src="/assets/images/icon-aridrop.png" alt="img" />
                    </div>
                    <p>KIBBLE AIRDROP</p>
                  </ButtonShop>
                  <ButtonShop onClick={handleShowShop}>
                    <div className="img-btn">
                      <img src="/assets/images/icon-shop.png" alt="img" />
                    </div>
                    <p>KIBBLE SHOP</p>
                  </ButtonShop>
                </AssetsWrapTab>
              </AssetsWrap>
            ) : (
              <></>
            )}
          </AssetsContentTop>
          {!showShop ? (
            <>
              <FormSubmit>
                {!infoUser?.address ? (
                  <>
                    <h1>Enter your address</h1>
                    <input
                      type="text"
                      placeholder="Enter address"
                      onChange={onChangAddress}
                    />
                  </>
                ) : (
                  <>
                    <h1>Your WALLET ADDRESS</h1>
                    <AddressUser>
                      <p>{shortenAddress(infoUser.address)}</p>
                      <div className="img-copy" onClick={handleCopyAddress}>
                        <img src="/assets/images/icon-copy.png" alt="copy" />
                      </div>
                    </AddressUser>
                    <WrapCountDownTime>
                      <div className="img-title">
                        <img
                          src="/assets/images/img-title.png"
                          alt="Your Airdrop"
                        />
                      </div>
                      <CountDownTimeBlock>
                        <TimesRamdom>
                          <BlockTimes>
                            {tokenAirdrop ? convertFixed(tokenAirdrop.airdrop_amount) : 0}
                          </BlockTimes>
                          <div className="img-coin">
                            <img src="/assets/images/coin.webp" alt="time" />
                          </div>
                        </TimesRamdom>
                        {
                          !isClaim ?  
                          <WrapTimes>
                          <h1>{formatUnitTime(days)}D</h1>
                          <h1>:</h1> <h1>{formatUnitTime(hours)}H</h1>{" "}
                          <h1>:</h1> <h1>{formatUnitTime(minutes)}M</h1>{" "}
                          <h1>:</h1> <h1>{formatUnitTime(seconds)}S</h1>
                          </WrapTimes> :  
                          <WrapTimes>
                            <h1>{tokenAirdrop?.has_claimed ? 'You have claimed' : 'Claim your token'} </h1>
                          </WrapTimes>
                        }
                       
                      </CountDownTimeBlock>
                    </WrapCountDownTime>
                  </>
                )}
              </FormSubmit>
              {!infoUser?.address ? (
                <ConnectWallet onClick={handleSubmitWallet}>
                  <span>Confirm</span>
                </ConnectWallet>
              ) : (
                 tokenAirdrop?.has_claimed ? 
                <ConnectWallet
                  style={{
                    opacity:  0.7,
                    pointerEvents: "none",
                  }}
                >
                  <span>CLAIMED</span>
                  </ConnectWallet> :<ConnectWallet
                  style={{
                    opacity: isClaim && !isLoading ? 1 : 0.7,
                    pointerEvents:isClaim && !isLoading ? "auto" : "none",
                  }}
                  onClick={handleClaim}
                >
                  <span>{isLoading ? 'CLAIMING...' : 'CLAIM'}</span>
                </ConnectWallet>
              )}
            </>
          ) : (
            <AssetsContentInner>
              <AssetsShopV2 setOverlayPremium={setOverlayPremium} />
            </AssetsContentInner>
          )}
        </AssetsContent>
      </AssetsWrapper>
    </AssetsContainer>
  );
};

const listToken = [
  {
    icon: icon01,
    name: "KIB POINT",
    price: "144,932,982,328",
  },
  {
    icon: icon01,
    name: "KIB POINT",
    price: "144,932,982,328",
  },
  {
    icon: icon01,
    name: "KIB POINT",
    price: "144,932,982,328",
  },
  {
    icon: icon01,
    name: "KIB POINT",
    price: "144,932,982,328",
  },
  {
    icon: icon01,
    name: "KIB POINT",
    price: "144,932,982,328",
  },
];

const listProduct = [
  {
    id: 1,
    img: product01,
    price: 200000,
    priceShort: 100,
  },
  {
    id: 2,
    img: product02,
    price: 500000,
    priceShort: 300,
  },
  {
    id: 3,
    img: product03,
    isFull: true,
    price: 20000000,
    priceShort: 2000,
  },
];

export default AssetsNew;
