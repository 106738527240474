import { useEffect, useState } from 'react';
import {
  LoadingContainer,
  LoadingProcess,
  LoadingProcessLoaded,
} from './styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from '../../redux/store';
import {
  getDataInfoUser,
  getDataLeaderBoard,
  getDataListAsset,
  getDataListFriends,
  getDataListTask,
  getListPackageEnergy,
  getListPointOfInviteFriends,
  getTimeNextRefill,
} from '../../redux/features/pointSlice';
import { useInitData } from '@tma.js/sdk-react';
import { KIBBLE_API } from '../../services/api';
import WebApp from '@twa-dev/sdk';
import { useTonAddress } from '@tonconnect/ui-react';
import { getTokenInWallet } from '../../utils/getTokenInWallet';

export default function LoadingV2() {
  const [processing, setProcessing] = useState(0);
  const [isLogin, setIsLogin] = useState(false);

  const dispatch = useDispatch();
  const { initDataUnsafe } = WebApp;

  const dataUserTele: any = useInitData();

  const {
    initData: { user },
  } = dataUserTele;

  const intervalLoadingProcess = '';

  const address = useTonAddress();

  const handlePrepareData = async () => {
    try {
      Promise.all([
        dispatch(getDataListTask()),
        dispatch(getDataListFriends()),
        dispatch(getDataLeaderBoard()),
        dispatch(getListPointOfInviteFriends()),
        dispatch(getListPackageEnergy()),
        dispatch(getTimeNextRefill()),
        dispatch(getDataListAsset(address)),
      ]);
      setInterval(() => {
        const percentRandom = Math.floor(Math.random() * 25);
        setProcessing((prev) => Math.min(prev + percentRandom, 100));
      }, 200);
    } catch (error) {
      console.log('====================================');
      console.log('handlePrepareData err', error);
      console.log('====================================');
    }
  };

  const handleLoginWithTele = async (payload: any) => {
    try {
      const { data } = await KIBBLE_API.loginWithTele(payload);
      await localStorage.setItem('token', data.token);
      setIsLogin(true);
      setProcessing(25);
    } catch (error) {
      console.log('====================================');
      console.log('handleLoginWithTele err', error);
      console.log('====================================');
    }
  };

  useEffect(() => {
    if (user) {
      const startParams = initDataUnsafe?.start_param;
      const listAddressFromParams: any = startParams?.split('=');
      const payload = {
        telegram_id: `${user.id}`,
        telegram_name: `${user.firstName} ${user.lastName}`,
        referral_code:
          listAddressFromParams && listAddressFromParams.length > 0
            ? listAddressFromParams[0]
            : '',
        is_premium: user.isPremium,
      };
      handleLoginWithTele(payload);
    }
  }, []);

  useEffect(() => {
    if (!isLogin) return;
    handlePrepareData();
  }, [isLogin]);

  useEffect(() => {
    if (processing === 100) {
      clearInterval(intervalLoadingProcess);
      dispatch(getDataInfoUser(user));
    }
  }, [processing]);

  return (
    <LoadingContainer className="animate__animated animate__fadeIn">
      <div className="img-name">
        <img src="/static/img-name-game.png" alt="game" />
      </div>
      <div className="img-monkey-loading">
        <img src="/static/img-monkey-loading.png" alt="loading" />
      </div>
      <LoadingProcess>
        <p>{processing}%</p>
        <LoadingProcessLoaded process={processing}></LoadingProcessLoaded>
      </LoadingProcess>
      <div className="img-bottom-left">
        <img src="/static/img-bottom-left.png" alt="left" />
      </div>
      <div className="img-bottom-right">
        <img src="/static/img-bottom-right.png" alt="right" />
      </div>
      <div className="img-bg-loading">
        <img src="/static/bg-loading.png" alt="" />
      </div>
    </LoadingContainer>
  );
}
